import i18n, {ReadCallback} from 'i18next';
import {initReactI18next} from 'react-i18next';
import { supportedLocales } from '@webng-types/i18n';

import liveblogEn from '@webng/i18n-common/src/translations/liveblog.json'
import liveblogDe from '@webng/i18n-common/src/translations/liveblog.de.json'
import liveblogEnGb from '@webng/i18n-common/src/translations/liveblog.en-GB.json'
import liveblogZhTw from '@webng/i18n-common/src/translations/liveblog.zh-TW.json'
import liveblogFrFr from '@webng/i18n-common/src/translations/liveblog.fr-FR.json'
import liveblogItIt from '@webng/i18n-common/src/translations/liveblog.it-IT.json'
import liveblogNlNl from '@webng/i18n-common/src/translations/liveblog.nl-NL.json'


function scoreLocale(locale: string, supportedLocale: string): number {
  if(locale === supportedLocale) {
    return 10;
  } else if(locale.startsWith(supportedLocale)) {
    return 5;
  } else if(locale.split("-")[0] === supportedLocale.split("-")[0]) {
    return 4;
  } else if(supportedLocale === 'en') {
    return 1;
  } else {
    return 0
  }
}

export function findClosestLanguage(locale: string): string {
  locale = locale.toLocaleLowerCase()
  return supportedLocales.map(supportedLocale => ({supportedLocale, score: scoreLocale(locale, supportedLocale)}))
    .sort((a, b) => b.score - a.score)[0]
    .supportedLocale;
}

export function detectHtmlLanguage(): string {
  if(document.documentElement.lang) {
    return findClosestLanguage(document.documentElement.lang);
  } else {
    return findClosestLanguage(navigator.language);
  }
}

// const languageDetector: LanguageDetectorModule = {
//   type: 'languageDetector',
//   init: function(services, detectorOptions, i18nextOptions) {
//     /* use services and options */
//   },
//   detect:detectHtmlLanguage,
//   cacheUserLanguage: function(lng) {}
// }

function i18nFileName(name: string, language: string) {
  if(language === 'en') {
    return `${name}.json`;
  } else {
    const languageParts = language.split("-");
    if (languageParts.length > 1){
      languageParts[languageParts.length -1] = languageParts[languageParts.length -1].toUpperCase();
    }
    return `${name}.${languageParts.join("-")}.json`;
  }
}

i18n
  // .use(languageDetector)
  .use(initReactI18next)
  .use({
    type: 'backend',
    read(
      language: string,
      namespace: string,
      callback: ReadCallback
    ) {
      if(namespace === 'sports') {
        if(language === 'en') {
          import(`@webng/sports/i18n/sports.json`).then(mod => callback(null, mod))
        } else {
          import(`@webng/sports/i18n/${i18nFileName("sports", language)}`).then(mod => callback(null, mod))
        }

      } else {
        callback(new Error(`can not dynamically load ${namespace} for en/de`), null)
      }
    }
  })
  .init({
    resources: {
      en: {liveblog: liveblogEn},
      de: {liveblog: liveblogDe},
      'en-gb': {liveblog: liveblogEnGb},
      'en-ie': {liveblog: liveblogEnGb},
      'en-au': {liveblog: liveblogEnGb},
      'en-nz': {liveblog: liveblogEnGb},
      'zh-tw': {liveblog: liveblogZhTw},
      'fr-fr': {liveblog: liveblogFrFr},
      'it-it': {liveblog: liveblogItIt},
      'nl-nl': {liveblog: liveblogNlNl},
    },
    lowerCaseLng: true,
    lng: 'en',
    fallbackLng: "en",
    supportedLngs: [...supportedLocales],
    detection: {},
    interpolation: {
      escapeValue: false
    },
    defaultNS: "liveblog",
    ns: ["liveblog"],
    debug: window.location.href.includes("localhost"),
    load: 'currentOnly'
  })

export default i18n
