import {base58ToInt, intToBase58} from "./Base58";


const MAX_RANDOM_4 = base58ToInt("ZZZZ")  // 1679616 .to_s(36) = "zzzz"

let counter = Math.floor(Math.random() * MAX_RANDOM_4);

function generateGUID(userId: string, prefix: string) {

  const userIdCut1 = userId.substr(4, 8)
  const userIdCut2 = userId.substr(12, 8)
  const userIdCompressed1 = intToBase58(parseInt(userIdCut1, 16)).substr(0, 4)
  const userIdCompressed2 = intToBase58(parseInt(userIdCut2, 16)).substr(0, 4)

  const timestamp = intToBase58(new Date().getTime())
  counter = counter % MAX_RANDOM_4
  counter = counter + 1
  const randomId = intToBase58(counter)

  return prefix +
    timestamp.padStart(7, '0') +
    userIdCompressed1.padStart(4, '0') +
    userIdCompressed2.padStart(4, '0') +
    randomId.padStart(4, '0')
}

export function generateGameGUID(userId: string) {
  return generateGUID(userId, "g")
}

export function generateEventGUID(userId: string) {
  return generateGUID(userId, "e")
}

export function generateMediaGUID(userId: string) {
  return generateGUID(userId, "m")
}

export function generateModificationGUID(userId: string) {
  return generateGUID(userId, "c")
}

export function generateContentBlockGUID(userId: string) {
  return generateGUID(userId, "b")
}

export function generateTagGUID(userId: string) {
  return generateGUID(userId, "t")
}

export function generateTempFileGUID(userId: string) {
  return generateGUID(userId, "tmp")
}

export function generateContentStreamGUID(userId: string) {
  return generateGUID(userId, "cs")
}

export function generateEventTagGUID(userId: string) {
  return generateGUID(userId, "tag")
}

export function generateTeamGUID(userId: string) {
  return generateGUID(userId, "tm")
}

export function generatePlayerGUID(userId: string) {
  return generateGUID(userId, "pl")
}

export function generateRandomId(prefix: string) {
  const userIdCompressed1 = intToBase58(Math.floor(Math.random() * MAX_RANDOM_4))
  const userIdCompressed2 = intToBase58(Math.floor(Math.random() * MAX_RANDOM_4))

  const timestamp = intToBase58(new Date().getTime())
  counter = counter % MAX_RANDOM_4
  counter = counter + 1
  const randomId = intToBase58(counter)

  return prefix +
    timestamp.padStart(7, '0') +
    userIdCompressed1.padStart(4, '0') +
    userIdCompressed2.padStart(4, '0') +
    randomId.padStart(4, '0')
}
