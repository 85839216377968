import {IEvent} from "@webng-types/write-model";
import {AbstractAdManager} from "./AbstractAdManager";
import {EventExtension} from "@webng/liveblog";


type AdCodeFunction = (index: number) => string
type AdCode = string|AdCodeFunction

interface ComplexAdRule {
  adCode: AdCode

  first?: number
  every?: number

  eventType?: string
  eventTypes?: string
  teamId?: string
  teamIds?: string

  maxMatches?: number
}


function extractRawId(localId: string | undefined) {
  return localId ? localId.split('/').pop() : undefined;
}

function ruleMatches(rule: ComplexAdRule, event: IEvent) {
  let hasRule: boolean|undefined = undefined

  console.log(rule, event)
  if(rule.eventType) {
    hasRule = true

    if(!event.sport_event) return false
    if(event.sport_event.event_type !== rule.eventType) return false
  }
  if(rule.eventTypes) {
    hasRule = true

    if(!event.sport_event) return false
    if(!event.sport_event.event_type) return false
    if(!rule.eventTypes.includes(event.sport_event.event_type)) return false
  }
  if(rule.teamId) {
    hasRule = true

    if(!event.sport_event) return false
    if(!event.sport_event.team) return false

    const eventTeamLocalId = event.sport_event.team.local_id;
    const eventTeamRawId = extractRawId(eventTeamLocalId);
    if (eventTeamLocalId !== rule.teamId && eventTeamRawId !== rule.teamId) return false;
  }
  if(rule.teamIds) {
    hasRule = true

    if(!event.sport_event) return false
    if(!event.sport_event.team) return false
    if(!event.sport_event.team.local_id) return false

    const eventTeamLocalId = event.sport_event.team.local_id;
    const eventTeamRawId = extractRawId(eventTeamLocalId);

    if(!eventTeamRawId) return false;
    if (!rule.teamIds.includes(eventTeamLocalId) && !rule.teamIds.includes(eventTeamRawId)) return false;
  }

  return hasRule
}

function scheduleRule(rule: ComplexAdRule, startIndex: number, adPositions: (AdCode|undefined)[]) {
  const firstIndex = startIndex + (rule.first || 0)
  if(rule.every) {
    for(let i = firstIndex; i < adPositions.length; i+= rule.every) {
      if(!adPositions[i]) {
        adPositions[i] = rule.adCode
      }
    }
  } else if(!adPositions[firstIndex] ) {
    adPositions[firstIndex] = rule.adCode
  }
}

function applyRule(rule: ComplexAdRule, rows: IEvent[], adPositions: (AdCode|undefined)[]) {
  let matches = 0
  for(let i = 0; i < rows.length; ++i) {
    const isMatch = ruleMatches(rule, rows[i])
    if(isMatch) {
      matches++
      scheduleRule(rule, i, adPositions)
      if(rule.maxMatches && matches >= rule.maxMatches) {
        break;
      }
    } else if(isMatch === undefined && i === 0) {
      scheduleRule(rule, i, adPositions)
      break;
    }
  }
}

export class ComplexAdManager extends AbstractAdManager {
  private readonly _configuration: ComplexAdRule[]

  constructor(position: keyof EventExtension, configuration: ComplexAdRule[]) {
    super(position)
    this._configuration = configuration
  }

  update(rows: IEvent[]) {
    const adPositions: (AdCode|undefined)[] = Array(rows.length).fill(undefined)

    this._configuration.forEach(adRule => {
      applyRule(adRule, rows, adPositions);
    })

    adPositions.forEach((value, index) => {
      if(typeof value === 'function') {
        this.notify(rows[index], value(index))
      } else {
        this.notify(rows[index], value)
      }
    })
  }
}


